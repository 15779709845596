
export function setSettings(value) {
	return {
		type: 'SET_SETTINGS',
		value
	};
}

// HACK::add doNotResetAdminPageSetting to get around bug where SET_DEFAULT_SETTINGS causes refresh
export function setDefaultSettings(value, doNotResetAdminPageSetting = false) {
	return {
		type: 'SET_DEFAULT_SETTINGS',
		value,
		doNotResetAdminPageSetting
	};
}

export function setInitialSettings() {
	return {
		type: 'SET_INITIAL_SETTINGS'
	};
}

export function resetSettings(value) {
	return {
		type: 'RESET_DEFAULT_SETTINGS',
		value
	};
}
