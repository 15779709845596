import localForage from 'localforage';
import { persistReducer } from 'redux-persist';
import { createSelector } from '@reduxjs/toolkit';
import _ from '@lodash';
import { RootState } from 'app/store';
import { WorkflowsState, WorkflowsActionTypes } from 'app/store/types';

const initialState: WorkflowsState = {};

const workflowsReducer = (state = initialState, action: WorkflowsActionTypes) => {
	switch (action.type) {
		case 'GET_LICENSE_GROUP_DATA_SUCCESS': {
			const {
				licenseGroupId,
				data: { workflows }
			} = action.payload;

			return {
				...state,
				[licenseGroupId]: {
					...state[licenseGroupId],
					...(workflows && {
						byId: _.mapValues(workflows, workflow => ({
							...workflow,
							logs: state[licenseGroupId]?.byId?.[workflow.id]?.logs
						}))
					})
				}
			};
		}
		case 'GET_SELECTED_WORKFLOW_LOGS': {
			const { licenseGroupId, workflowId, data } = action.payload;

			return {
				...state,
				[licenseGroupId]: {
					byId: {
						...state[licenseGroupId].byId,
						[workflowId]: {
							...state[licenseGroupId].byId[workflowId],
							logs: data
						}
					}
				}
			};
		}
		case 'GET_WORKFLOW_DATA': {
			const { licenseGroupId, workflowId, data } = action.payload;

			return {
				...state,
				[licenseGroupId]: {
					byId: {
						...state[licenseGroupId].byId,
						[workflowId]: {
							...state[licenseGroupId].byId[workflowId],
							...data
						}
					}
				}
			};
		}
		case 'LOGGED_OUT_USER':
		case 'PURGE_STATE':
			return initialState;
		default: {
			return state;
		}
	}
};

export default persistReducer(
	{
		key: 'workflows',
		storage: localForage,
		whitelist: localStorage.getItem('persist') ? undefined : []
	},
	workflowsReducer
);

// Selectors
export const getWorkflowsById = ({ app: { selectedLicenseGroupId }, workflows }: RootState) =>
	workflows[selectedLicenseGroupId].byId;

export const getWorkflows = createSelector([getWorkflowsById], workflowsById => Object.values(workflowsById));
