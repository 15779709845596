import navigationConfig from 'app/fuse-configs/navigationConfig';
import * as Actions from '../../actions/fuse';

const initialState = navigationConfig;

const navigation = (state = initialState, action) => {
	switch (action.type) {
		case 'GET_NAVIGATION': {
			return [...state];
		}
		case 'SET_NAVIGATION': {
			return [...action.navigation];
		}
		case 'RESET_NAVIGATION': {
			return [...initialState];
		}
		default: {
			return state;
		}
	}
};

export default navigation;
