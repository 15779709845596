// eslint-disable-next-line no-restricted-imports
import axios from 'axios';
import mocking from './mocking';

const instance = axios.create({
	responseType: 'json',
	headers: {
		'Content-Type': 'application/json'
		// DEV NOTE::`Authentication` header bearer token is added after `getProfile` call in `profile.actions.ts`
	}
});

const instanceNoAuthToken = axios.create({
	responseType: 'json',
	headers: {
		'Content-Type': 'application/json'
	}
});

export default instance;
export { instanceNoAuthToken as axiosNoAuthToken };

mocking(instance);
