import localForage from 'localforage';
import { persistReducer } from 'redux-persist';
import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'app/store';
import { UsersState, UsersActionTypes } from 'app/store/types';

const initialState: UsersState = {};

const usersReducer = (state = initialState, action: UsersActionTypes) => {
	switch (action.type) {
		case 'GET_LICENSE_GROUP_DATA_SUCCESS': {
			const {
				licenseGroupId,
				data: { users, pendingUsers }
			} = action.payload;

			return {
				...state,
				[licenseGroupId]: {
					...state[licenseGroupId],
					...(users && { byId: users }),
					...(pendingUsers && { pendingById: pendingUsers })
				}
			};
		}
		case 'LOGGED_OUT_USER':
		case 'PURGE_STATE':
			return initialState;
		default: {
			return state;
		}
	}
};

export default persistReducer(
	{
		key: 'users',
		storage: localForage,
		whitelist: localStorage.getItem('persist') ? undefined : []
	},
	usersReducer
);

// Selectors
export const getUsersById = ({ app: { selectedLicenseGroupId }, users }: RootState) =>
	users[selectedLicenseGroupId].byId;

export const getUsers = createSelector([getUsersById], usersById => Object.values(usersById));

export const getPendingUsersById = ({ app: { selectedLicenseGroupId }, users }: RootState) =>
	users[selectedLicenseGroupId].pendingById;

export const getAllUsersById = createSelector([getUsersById, getPendingUsersById], (usersById, pendingUsersById) => ({
	...usersById,
	...pendingUsersById
}));

export const getAllUsers = createSelector([getAllUsersById], allUsersById => Object.values(allUsersById));
