import { PurgeStateAction } from './licenseGroups.types';

export type Receipt = {
	token: string;
	status: 'SUCCESS';
	order: {
		subtotal: number;
		tax: number;
		total: number;
		items: {
			[catalogId: number]: {
				name: string;
				quantity: number;
			};
		};
	};
};
// TODO: UPDATE PUBLIC IDS
export enum PublicId {
	StarterMonthly = 'DEPRECATED::00919D56-7103-4397-B9DD-4E8AA42F2863',
	StarterYearly = 'DEPRECATED::FAKE0000-0000-0000-0000-000000000001',
	StarterYearlyTerm = 'DEPRECATED::43253F38-F3E2-402B-B725-41DAEE6B7A93',
	BusinessMonthly = 'DEPRECATED::35F75645-0FA2-4CAF-A25C-71F52BDDD8EC',
	BusinessYearly = 'DEPRECATED::FAKE0000-0000-0000-0000-000000000003',
	BusinessYearlyTerm = 'DEPRECATED::A2A861B3-D24D-447F-A1B4-6AC6F2A0666C',
	EnterpriseYearly = 'DEPRECATED::FAKE0000-0000-0000-0000-000000000005',
	EnterpriseYearlyTerm = 'DEPRECATED::706832BE-B413-48A8-AEFE-8434D72F78B6',

	FreeTrial = '012FA085-C7A7-4117-BBFF-8D55BFF07AAF',
	NFR = 'AF53EC30-C8B8-48B7-82E3-E4574C708A18',
	EnterpriseMonthly = '3348C5FD-7123-4332-ABD0-4D5C644CE3A7',
	// EnterpriseMonthlyOneTime = '1E92B38B-EEA4-4B88-9DAB-CBDE4A9D472B',
	TermOneYear = 'F34EB719-1E3E-4AA9-B31B-AE691B0AB090',
	TermTwoYear = '04847520-17F3-4582-A1DA-827D7D4D2B52',
	TermThreeYear = '2B4EEAA7-9576-43C8-820D-25C09DAC99B3',
	TermFourYear = '3A14C409-D4CB-4C16-A346-B5D732570A17',
	TermFiveYear = 'A0C7C4C6-FF94-44D1-AD3F-16E5274385F5',
	TermBUSBundleOneYear = 'C3F39D09-6091-4302-8C31-C78A81F1ADAE',
	TermBUSBundleTwoYear = '1293F292-D870-4578-9ED0-50D151A46820',
	TermBUSBundleThreeYear = '6852DB6D-DD15-4910-A68F-63CAEE12EC17',
	TermBUSBundleFourYear = 'ED4FA711-9159-4437-9398-1AEC3CA4B196',
	TermBUSBundleFiveYear = '0FA99FF0-16D2-4971-9CD5-157F99F2ED29',
	TermDealerBundle = 'CB73A7BE-5C65-41D0-9312-D95DA2301E3B',

	StratusFreeTrial = '712B121A-E10F-40F3-96B7-5823E5475F8A',
	StratusNFR = 'E791337D-6722-487A-AE63-E17CDFA8CB3A',
	TempPeopleLicense = 'D8EC0E60-FABE-40E5-B0E2-68D9E0AD5F70',
	StratusTestCatalogItem = '5837D291-31A7-47C2-B9B6-6712468C8B73',

	OnePeopleLicenseFiveYear = '309201E3-6482-4BC0-BDBB-37DD3CF4F488',
	OnePeopleLicenseFourYear = 'A974ABCF-2D72-4DC7-A1DB-3D839335BA73',
	OnePeopleLicenseThreeYear = 'E3FC89C6-E56A-4DE9-B2DF-D13CDF38EB61',
	OnePeopleLicenseTwoYear = '5BAEFAE4-9469-449F-9043-28992F137480',
	OnePeopleLicenseOneYear = '6D0F7639-1251-4032-8254-B0DA4872CE93',
	OneDeviceLicenseFiveYear = '86EE6148-DBC6-41E7-9D75-2200A349511C',
	OneDeviceLicenseFourYear = 'EE08CEBF-2AAC-44E0-A899-7489547BEF8F',
	OneDeviceLicenseThreeYear = 'C1512F4C-42F1-4F7E-A477-D635C2B5AFC5',
	OneDeviceLicenseTwoYear = '53FDDC16-E37E-4712-BE6D-34E4D08977D2',
	OneDeviceLicenseOneYear = '539FF6A8-86DF-4B3B-B9C3-556637F6DB85'
}

// TODO::make a better way of determining if a PublicId is a stratus PublicId (also this isn't a type... [though neither is an `enum`])
export const stratusPublicIds = [
	PublicId.StratusFreeTrial,
	PublicId.StratusNFR,
	PublicId.TempPeopleLicense,
	PublicId.StratusTestCatalogItem,

	PublicId.OnePeopleLicenseFiveYear,
	PublicId.OnePeopleLicenseFourYear,
	PublicId.OnePeopleLicenseThreeYear,
	PublicId.OnePeopleLicenseTwoYear,
	PublicId.OnePeopleLicenseOneYear,
	PublicId.OneDeviceLicenseFiveYear,
	PublicId.OneDeviceLicenseFourYear,
	PublicId.OneDeviceLicenseThreeYear,
	PublicId.OneDeviceLicenseTwoYear,
	PublicId.OneDeviceLicenseOneYear
];

export type CatalogItem = {
	publicId: PublicId;
	name: string;
	productionDuration: number | undefined;
	showPrice: boolean;
	defaultPrice: number;
	priceRanges: {
		amount: number;
		min: number;
		sapId: string;
	}[];
	billing: {
		uid: 'bonce' | 'bmonth' | 'bmonth14g' | 'bninety' | 'bquarter' | 'bweek' | 'byear';
		// name: string;
		// duration: number;
		// months: boolean;
		// gracePeriod: number;
	};
	counter: {
		min: number;
		max: number;
		step: number;
		// lock: boolean;
	};
	type?: 'device' | 'people' | 'deviceUpgrade' | 'peopleUpgrade';
	plan?: 'stc' | 'stratus-starter' | 'stratus-business' | 'stratus-enterprise';
	upgrade?: {
		fromPlan: 'stc' | 'stratus-starter' | 'stratus-business';
		toPlan: 'stratus-starter' | 'stratus-business' | 'stratus-enterprise';
	};
};

export type MarketState = {
	currency: string;
	catalog: {
		[publicId in PublicId]: CatalogItem;
	};
};

type GetCatalogSuccessAction = {
	type: 'GET_CATALOG_SUCCESS';
	payload: {
		currency: string;
		catalog: {
			[publicId in PublicId]: CatalogItem;
		};
	};
};

export type MarketActionTypes = GetCatalogSuccessAction | PurgeStateAction;
