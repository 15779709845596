import i18next, { Resource } from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { licenseGroupPlanDesignCheck } from 'app/utils/tenant-plan';

import { registerLocale, setDefaultLocale } from 'react-datepicker';
import jaLocale from 'date-fns/locale/ja';
import esLocale from 'date-fns/locale/es';
import deLocale from 'date-fns/locale/de';
import itLocale from 'date-fns/locale/it';
import frLocale from 'date-fns/locale/fr';
import zhLocale from 'date-fns/locale/zh-CN';

import common from './locales/en/common.json';
import alert from './locales/en/alert.json';
import pageTitle from './locales/en/pageTitle.json';
import region from './locales/en/region.json';
import timezone from './locales/en/timezone.json';
import log from './locales/en/log.json';
import inbox from './locales/en/inbox.json';

import commonEs from './locales/es/common.json';
import alertEs from './locales/es/alert.json';
import pageTitleEs from './locales/es/pageTitle.json';
import regionEs from './locales/es/region.json';
import timezoneEs from './locales/es/timezone.json';
import logEs from './locales/es/log.json';
import inboxEs from './locales/es/inbox.json';

import commonDe from './locales/de/common.json';
import alertDe from './locales/de/alert.json';
import pageTitleDe from './locales/de/pageTitle.json';
import regionDe from './locales/de/region.json';
import timezoneDe from './locales/de/timezone.json';
import logDe from './locales/de/log.json';
import inboxDe from './locales/de/inbox.json';

import commonFr from './locales/fr/common.json';
import alertFr from './locales/fr/alert.json';
import pageTitleFr from './locales/fr/pageTitle.json';
import regionFr from './locales/fr/region.json';
import timezoneFr from './locales/fr/timezone.json';
import logFr from './locales/fr/log.json';
import inboxFr from './locales/fr/inbox.json';

import commonIt from './locales/it/common.json';
import alertIt from './locales/it/alert.json';
import pageTitleIt from './locales/it/pageTitle.json';
import regionIt from './locales/it/region.json';
import timezoneIt from './locales/it/timezone.json';
import logIt from './locales/it/log.json';
import inboxIt from './locales/it/inbox.json';

import commonJa from './locales/ja/common.json';
import alertJa from './locales/ja/alert.json';
import pageTitleJa from './locales/ja/pageTitle.json';
import regionJa from './locales/ja/region.json';
import timezoneJa from './locales/ja/timezone.json';
import logJa from './locales/ja/log.json';
import inboxJa from './locales/ja/inbox.json';

import commonMock from './locales/mock/common.json';
import alertMock from './locales/mock/alert.json';
import pageTitleMock from './locales/mock/pageTitle.json';
import regionMock from './locales/mock/region.json';
import timezoneMock from './locales/mock/timezone.json';
import logMock from './locales/mock/log.json';
import inboxMock from './locales/mock/inbox.json';

// register locales for react-datepicker
const dateFnsLocales = {
	ja: jaLocale,
	es: esLocale,
	de: deLocale,
	it: itLocale,
	fr: frLocale,
	'zh-CN': zhLocale
} as const;

Object.entries(dateFnsLocales).forEach(([key, value]) => {
	registerLocale(key, value);
});

const mergeAppName = (json: { [key: string]: string }) => ({
	...json,
	'app:name': licenseGroupPlanDesignCheck('stratus') ? 'Dispatcher Stratus' : 'Dispatcher ScanTrip Cloud'
});

const mockNamespaces = {
	common: mergeAppName(commonMock),
	alert: alertMock,
	pageTitle: pageTitleMock,
	region: regionMock,
	timezone: timezoneMock,
	log: logMock,
	inbox: inboxMock
};

i18next
	.use(LanguageDetector)
	.use(initReactI18next)
	.init({
		fallbackLng: 'en',
		// referenceLng: 'en',
		// whitelist: ['en'],
		load: 'languageOnly',

		debug: false,
		ns: ['common', 'alert', 'pageTitle', 'region', 'timezone', 'log', 'inbox'],
		defaultNS: 'common',
		returnEmptyString: false,
		keySeparator: ';',
		nsSeparator: '::',
		interpolation: {
			escapeValue: false
		},
		resources: localStorage.getItem('mockT')
			? ['en', 'es', 'de', 'fr', 'it', 'ja'].reduce((acc, lang) => {
					acc[lang] = mockNamespaces;
					return acc;
			  }, {} as Resource)
			: {
					en: {
						common: mergeAppName(common),
						alert,
						pageTitle,
						region,
						timezone,
						log,
						inbox
					},
					es: {
						common: mergeAppName(commonEs),
						alert: alertEs,
						pageTitle: pageTitleEs,
						region: regionEs,
						timezone: timezoneEs,
						log: logEs,
						inbox: inboxEs
					},
					de: {
						common: mergeAppName(commonDe),
						alert: alertDe,
						pageTitle: pageTitleDe,
						region: regionDe,
						timezone: timezoneDe,
						log: logDe,
						inbox: inboxDe
					},
					fr: {
						common: mergeAppName(commonFr),
						alert: alertFr,
						pageTitle: pageTitleFr,
						region: regionFr,
						timezone: timezoneFr,
						log: logFr,
						inbox: inboxFr
					},
					it: {
						common: mergeAppName(commonIt),
						alert: alertIt,
						pageTitle: pageTitleIt,
						region: regionIt,
						timezone: timezoneIt,
						log: logIt,
						inbox: inboxIt
					},
					ja: {
						common: mergeAppName(commonJa),
						alert: alertJa,
						pageTitle: pageTitleJa,
						region: regionJa,
						timezone: timezoneJa,
						log: logJa,
						inbox: inboxJa
					}
			  }
	});

// set default locale for react-datepicker
let localeName = i18next.language;
if (localeName.length > 2) localeName = localeName.substring(0, 2);
if (localeName === 'zh') localeName = 'zh-CN';
if (localeName === 'en-US') localeName = 'en';

setDefaultLocale(localeName);

// @ts-ignore // is this supposed to be `getResource`? I think it's wrong but I don't wanna change it now...
i18next.getResources = (lang, namespace) =>
	!i18next.hasResourceBundle(lang, namespace) && i18next.reloadResources(lang, namespace);

export default i18next;
