import { darken, Theme } from '@material-ui/core/styles';

export const pageHeader = (_theme: Theme) =>
	({
		// position: 'relative', // for some inexplicable reason this breaks typescript...so this styling is added with tailwind
		padding: '0.8rem 0rem',
		color: '#7D61BC',
		'&::after': {
			position: 'absolute',
			content: '""',
			height: 1,
			bottom: '0.7rem',
			left: 0,
			marginLeft: '0rem',
			width: '100%',
			// maxWidth: 1100,
			background:
				'linear-gradient(to right, rgba(112,112,112,1), rgba(112,112,112,1),rgba(112,112,112,0.3),rgba(112,112,112,0),rgba(112,112,112,0),rgba(112,112,112,0),rgba(112,112,112,0),rgba(112,112,112,0))'
		}
	} as const);

export const tableDrawerPaper = (_theme: Theme) =>
	({
		position: 'absolute',
		minWidth: '25.6rem',
		borderTopLeftRadius: 4,
		borderBottomLeftRadius: 4,
		backgroundColor: '#262626',
		color: '#FFFFFF',
		'& .MuiListItemIcon-root, & .MuiIconButton-root': {
			color: 'inherit'
		}
	} as const);

export const messageView = (theme: Theme) =>
	({
		background: `radial-gradient(${darken(theme.palette.primary.dark, 0.5)} 0%, ${theme.palette.primary.dark} 80%)`,
		color: theme.palette.primary.contrastText,
		'[data-tier="enterprise"] &': {
			background: 'unset'
		}
	} as const);
