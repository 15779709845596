import clsx from 'clsx';
import AppBar from '@material-ui/core/AppBar';
import { makeStyles } from '@material-ui/core/styles';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import { Link as RouterLink, useHistory } from 'react-router-dom';
import Link from '@material-ui/core/Link';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import PurgeStateWrapper from 'app/components/PurgeStateWrapper';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'app/modules/react-redux';
import { useTranslation } from 'react-i18next';
import { getIsPossibleDevelopStore, getProfile } from 'app/store/reducers';
import { Button } from '@material-ui/core';
import * as Actions from 'app/store/actions';
import useGetPageSection from 'app/hooks/useGetPageSection';
import ErrorBoundary from 'app/components/ErrorBoundary';
import { getPrivacyPolicyUrl } from 'app/utils/helpers';

const useStyles = makeStyles(theme => ({
	root: {
		// color: '#22292F',
		// backgroundColor: '#EBEBEB'
		color: theme.palette.text.primary,
		backgroundColor: theme.palette.background.default
	},
	link: {
		// color: '#22292F !important',
		color: `${theme.palette.text.primary} !important`
	}
}));

function FooterLayout1() {
	const classes = useStyles();
	const history = useHistory();
	const { t } = useTranslation();
	const pageSection = useGetPageSection();
	const dispatch = useDispatch();

	const isPossibleDevelopStore = useSelector(getIsPossibleDevelopStore); // TEMP::skip any buttons for now develop store (as we don't know which to direct to)

	const profile = useSelector(getProfile) as ReturnType<typeof getProfile> | undefined; // HACK::use actual type here - assume never undefined within the wrapped app

	// HACK-ish::add option to open popup via path
	useEffect(() => {
		const searchParams = new URLSearchParams(history.location.search);
		if (searchParams.has('cookieSettings')) {
			dispatch(Actions.setCookieSettings(true));
			searchParams.delete('cookieSettings');
			history.replace({ search: searchParams.toString() });
		}
	}, [dispatch, history]);

	return (
		<AppBar id="fuse-footer" className={clsx(classes.root, 'relative z-10')} elevation={0}>
			<Toolbar className="flex flex-wrap items-center container py-0 px-16 lg:px-24" variant="dense">
				<div className="flex flex-1 text-12">
					<Breadcrumbs separator={<span className="mx-4">|</span>} aria-label="breadcrumb">
						{/* <Link
								className={clsx(classes.link, 'text-12')}
								href={getHelpUrl(i18n.language)}
								target="_blank"
								rel="noreferrer noopener"
							>
								{t('Help')}
							</Link> */}
						{/* TODO::add a contact page - maybe with a phone number as well? */}
						{!isPossibleDevelopStore && (
							<RouterLink
								className={clsx(classes.link, 'text-12')}
								to={pageSection.licenseGroupDomain ? '/admin/contact-us' : '/contact-us'}
							>
								{t('Contact Us')}
							</RouterLink>
							// <Link
							// 	className={clsx(classes.link, 'text-12')}
							// 	href="https://konicaminoltamarketplace.com/support/contact"
							// 	target="_blank"
							// 	rel="noreferrer noopener"
							// >
							// 	{t('Contact Us')}
							// </Link>
						)}
						<Button
							className={clsx(classes.link, 'font-normal hover:underline p-0 text-12')}
							onClick={() => dispatch(Actions.setCookieSettings(true))}
						>
							{t('Cookie Settings')}
						</Button>
						<Link
							color="inherit"
							className={clsx(classes.link, 'text-12')}
							href={getPrivacyPolicyUrl(profile?.awsRegion)}
							target="_blank"
							rel="noreferrer noopener"
						>
							{t('Privacy')}
						</Link>
						<RouterLink className={clsx(classes.link, 'text-12')} to="/terms">
							{t('Terms and Conditions')}
						</RouterLink>
						<RouterLink className={clsx(classes.link, 'text-12')} to="/eula">
							{t('EULA')}
						</RouterLink>
					</Breadcrumbs>
				</div>

				<div>
					<Typography className="text-12">
						<PurgeStateWrapper>
							<span>{t('about:developed by', { name: 'Solutions Engineering Center' })}</span>{' '}
							<span>© {new Date().getFullYear()} Konica Minolta Business Solutions, U.S.A., Inc.</span>
						</PurgeStateWrapper>
					</Typography>
				</div>
			</Toolbar>
		</AppBar>
	);
}

const MemoizedFooterLayout1 = React.memo(FooterLayout1);

const WrappedFooterLayout1 = () => (
	<ErrorBoundary>
		<MemoizedFooterLayout1 />
	</ErrorBoundary>
);
export default WrappedFooterLayout1;
