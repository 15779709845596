import localForage from 'localforage';
import { persistReducer } from 'redux-persist';
import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'app/store';
import { AppGroup, AppGroupsState, AppGroupsActionTypes } from 'app/store/types';

const initialState: AppGroupsState = {};

const appGroupsReducer = (state = initialState, action: AppGroupsActionTypes) => {
	switch (action.type) {
		case 'GET_LICENSE_GROUP_DATA_SUCCESS': {
			const {
				licenseGroupId,
				data: { appGroups }
			} = action.payload;

			let updatedGroups;
			if (appGroups) {
				// maintain order since order is not returned in that api call
				const groups = { ...state[licenseGroupId]?.byId };
				updatedGroups = Object.keys(appGroups).reduce((result: Record<string, AppGroup>, id) => {
					const existingGroup = groups[id];
					result[id] =
						existingGroup && existingGroup?.groupOrder
							? {
									...appGroups[id],
									groupOrder: existingGroup.groupOrder
								}
							: appGroups[id];
					return result;
				}, {});
			}

			return {
				...state,
				[licenseGroupId]: {
					...state[licenseGroupId],
					...(updatedGroups && { byId: updatedGroups })
				}
			};
		}
		case 'GET_APP_GROUP_ORDER_SUCCESS': {
			const { order, licenseGroupId } = action.payload;
			const groups = { ...state[licenseGroupId].byId };

			// order list may not contain all groups
			const existingGroupIds = order.filter((groupId: string) => groups[groupId]);
			const updatedGroups: Record<string, AppGroup> = {};

			existingGroupIds.forEach((groupId: string, idx: number) => {
				const group = { ...groups[groupId] };
				group.groupOrder = idx + 1;
				updatedGroups[groupId] = group;
			});

			// assign other group order for non existing groups in order array
			const nonOrderedGroups = Object.keys(groups).filter(groupId => !existingGroupIds.includes(groupId));

			nonOrderedGroups.forEach((groupId, idx) => {
				const group = { ...groups[groupId] };
				group.groupOrder = existingGroupIds.length + idx + 1;
				updatedGroups[groupId] = group;
			});

			return {
				...state,
				[licenseGroupId]: {
					byId: updatedGroups
				}
			};
		}
		case 'LOGGED_OUT_USER':
		case 'PURGE_STATE':
			return initialState;
		default: {
			return state;
		}
	}
};

export default persistReducer(
	{
		key: 'appGroups',
		storage: localForage,
		whitelist: localStorage.getItem('persist') ? undefined : []
	},
	appGroupsReducer
);

// Selectors

export const getAppGroupsById = ({ app: { selectedLicenseGroupId }, appGroups }: RootState) =>
	appGroups[selectedLicenseGroupId].byId;

export const getAppGroups = createSelector([getAppGroupsById], appGroupsById => Object.values(appGroupsById));

export const getAppGroupById = (appGroupId: AppGroup['id']) => (state: RootState) =>
	getAppGroupsById(state)[appGroupId];
