import localForage from 'localforage';
import { persistReducer } from 'redux-persist';
import { RootState } from 'app/store';
import { EntitlementsActionTypes, EntitlementsState } from 'app/store/types';
import _ from 'lodash';
import { arrayify } from 'app/utils/helpers';
import { createSelector } from '@reduxjs/toolkit';

const initialState: EntitlementsState = {
	permissions: {},
	features: {}
};

const entitlementsReducer = (state = initialState, action: EntitlementsActionTypes) => {
	switch (action.type) {
		case 'GET_LICENSE_GROUP_DATA_SUCCESS': {
			const {
				data: { entitlements }
			} = action.payload;
			return {
				...state,
				...(entitlements && { permissions: entitlements.permissions }),
				...(entitlements && { features: entitlements.features })
			};
		}
		case 'LOGGED_OUT_USER':
		case 'PURGE_STATE':
			return initialState;
		default: {
			return state;
		}
	}
};

export default persistReducer(
	{
		key: 'entitlements',
		storage: localForage,
		whitelist: localStorage.getItem('persist') ? undefined : []
	},
	entitlementsReducer
);

// Selectors
const getEntitlements = (state: RootState) => state.entitlements;

const getFeatures = createSelector([getEntitlements], entitlements => entitlements.features);

export const getPermissions = createSelector([getEntitlements], entitlements => entitlements.permissions);

export const getRolePermissionCheck = createSelector(
	[getPermissions],
	permissions => (permissionStringArg: string | string[]) => {
		if (localStorage.getItem('allPermissions')) {
			return true;
		}
		const permissionStrings = arrayify(permissionStringArg);
		return permissionStrings.some(permissionString => {
			const permission = _.get(permissions, permissionString, false);

			if (!_.isBoolean(permission)) {
				return false;
			}

			return permission;
		});
	}
);

export const getFeaturePermissionCheck = createSelector(
	[getFeatures],
	features => (permissionStringArg: string | string[]) => {
		if (localStorage.getItem('allPermissions')) {
			return true;
		}
		const permissionStrings = arrayify(permissionStringArg);
		return permissionStrings.some(permissionString => {
			return features[permissionString];
		});
	}
);
