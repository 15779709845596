import NavLinkAdapter from '@fuse/core/NavLinkAdapter';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import * as Actions from 'app/store/actions';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'app/modules/react-redux';
import { withRouter } from 'react-router-dom';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import FuseNavBadge from '../FuseNavBadge';

const useStyles = makeStyles(theme => ({
	item: props => ({
		height: 40,
		borderRadius: 0,
		paddingRight: 12,
		paddingLeft: props.itemPadding > 80 ? 80 : props.itemPadding,
		border: '1px solid transparent', // required to prevent reflow when `active` class is added
		'&.active': {
			border: '1px solid #7070705E',
			backgroundColor: '#231E245E !important',
			color: `${theme.palette.secondary.contrastText} !important`,
			transition: 'border-radius .15s cubic-bezier(0.4,0.0,0.2,1)',
			'& .nav-list-item-text-primary': {
				color: '#A48ADB',
				fontWeight: 700,
				'[data-tier="enterprise"] &': {
					color: theme.palette.text.primary
				}
			},
			'& .nav-list-item-icon': {
				color: '#A48ADB',
				'[data-tier="enterprise"] &': {
					color: theme.palette.text.primary
				}
			}
		},
		'& .nav-list-item-icon': {
			marginRight: 16,
			transform: 'scale(1.5) translateX(-1px)' // HACK-ish::required to re-center the icon dues to the 1px border
		},
		'& .nav-list-item-text': {},
		cursor: 'pointer',
		textDecoration: 'none!important',
		'& .fill-icon': {
			display: 'none'
		},
		'[data-tier="starter"] &:hover .fill-icon, [data-tier="starter"] &.active .fill-icon': {
			display: 'inline-block'
		},
		'[data-tier="starter"] &:hover .line-icon, [data-tier="starter"] &.active .line-icon': {
			display: 'none'
		}
	})
}));

function FuseNavVerticalItem(props) {
	// const userRole = useSelector(({ auth }) => auth.user.role);
	const dispatch = useDispatch();

	const theme = useTheme();
	const mdDown = useMediaQuery(theme.breakpoints.down('md'));
	const { item, nestedLevel } = props;
	const classes = useStyles({
		itemPadding: nestedLevel > 0 ? 40 + nestedLevel * 16 : 24
	});
	const { t } = useTranslation();

	// const hasPermission = useMemo(() => FuseUtils.hasPermission(item.auth, userRole), [item.auth, userRole]);

	// if (!hasPermission) {
	// 	return null;
	// }

	// const IconComponent = item.Icon;
	const LineIconComponent = item.LineIcon;
	const FillIconComponent = item.FillIcon;

	return (
		<ListItem
			button
			component={NavLinkAdapter}
			to={item.url}
			activeClassName="active"
			className={clsx(classes.item, 'nav-list-item mt-12 starter:text-white landing-toolbar-text-white')}
			onClick={_ev => mdDown && dispatch(Actions.navbarCloseMobile())}
			exact={item.exact}
		>
			{/* <IconComponent className="nav-list-item-icon text-16 flex-shrink-0 scale-150" color="action" /> */}
			<LineIconComponent className="nav-list-item-icon text-16 flex-shrink-0 scale-150 line-icon" />
			<FillIconComponent className="nav-list-item-icon text-16 flex-shrink-0 scale-150 fill-icon" />

			<ListItemText
				className="nav-list-item-text ml-20"
				primary={item.translate ? t(item.translate) : item.title}
				classes={{ primary: 'text-14 nav-list-item-text-primary' }}
				style={{ textWrap: 'wrap', wordBreak: 'break-word', whiteSpace: 'pre-wrap' }}
			/>

			{item.badge && <FuseNavBadge badge={item.badge} />}
		</ListItem>
	);
}

FuseNavVerticalItem.propTypes = {
	item: PropTypes.shape({
		id: PropTypes.string.isRequired,
		title: PropTypes.string,
		icon: PropTypes.string,
		url: PropTypes.string
	})
};

const NavVerticalItem = withRouter(React.memo(FuseNavVerticalItem));

export default NavVerticalItem;
