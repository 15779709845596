import localForage from 'localforage';
import { persistReducer } from 'redux-persist';
import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'app/store';
import { UserGroup, UserGroupsState, UserGroupsActionTypes } from 'app/store/types';

const initialState: UserGroupsState = {};

const userGroupsReducer = (state = initialState, action: UserGroupsActionTypes) => {
	switch (action.type) {
		case 'GET_LICENSE_GROUP_DATA_SUCCESS': {
			const {
				licenseGroupId,
				data: { userGroups }
			} = action.payload;

			let updatedGroups;
			if (userGroups) {
				// maintain order since order is not returned in that api call
				const groups = { ...state[licenseGroupId]?.byId };

				const existingGroups = Object.keys(userGroups)
					.filter(id => groups[id] && typeof groups[id]?.groupOrder === 'number')
					.reduce((result: Record<string, UserGroup>, id) => {
						const existingGroup = groups[id];
						result[id] = {
							...userGroups[id],
							groupOrder: existingGroup.groupOrder
						};
						return result;
					}, {});

				const existingGroupOrders = Object.values(existingGroups)
					.filter((group: UserGroup) => typeof group.groupOrder === 'number')
					.map((group: UserGroup) => group.groupOrder as number);
				const maxGroupOrder = existingGroupOrders.length > 0 ? Math.max(...existingGroupOrders) : -1;

				// Assign groupOrder to the rest of the groups
				const newGroups = Object.keys(userGroups)
					.filter(id => !groups[id] || typeof groups[id]?.groupOrder !== 'number')
					.reduce((result: Record<string, UserGroup>, id, index) => {
						result[id] = {
							...userGroups[id],
							groupOrder: maxGroupOrder + 1 + index
						};
						return result;
					}, {});

				updatedGroups = {
					...existingGroups,
					...newGroups
				};
			}
			return {
				...state,
				[licenseGroupId]: {
					...state[licenseGroupId],
					...(updatedGroups && { byId: updatedGroups })
				}
			};
		}
		case 'GET_USER_GROUP_ORDER_SUCCESS': {
			const { order, licenseGroupId } = action.payload;
			const groups = { ...state[licenseGroupId].byId };

			// order list may not contain all groups
			const existingGroupIds = order.filter((groupId: string) => groups[groupId]);
			const updatedGroups: Record<string, UserGroup> = {};

			existingGroupIds.forEach((groupId: string, idx: number) => {
				const group = { ...groups[groupId] };
				group.groupOrder = idx + 1;
				updatedGroups[groupId] = group;
			});

			// assign other group order for non existing groups in order array
			const nonOrderedGroups = Object.keys(groups).filter(groupId => !existingGroupIds.includes(groupId));

			nonOrderedGroups.forEach((groupId, idx) => {
				const group = { ...groups[groupId] };
				updatedGroups[groupId] = group;
			});

			return {
				...state,
				[licenseGroupId]: {
					byId: updatedGroups
				}
			};
		}
		case 'UPDATE_USER_GROUP_ORDER_SUCCESS': {
			const { order, licenseGroupId } = action.payload;
			const groups = { ...state[licenseGroupId].byId };
			const orderedGroups: Record<string, UserGroup> = {};

			order.forEach(groupId => {
				if (groups[groupId]) {
					const group = { ...groups[groupId] };
					orderedGroups[groupId] = group;
				}
			});

			return {
				...state,
				[licenseGroupId]: {
					byId: orderedGroups
				}
			};
		}
		case 'LOGGED_OUT_USER':
		case 'PURGE_STATE':
			return initialState;
		default: {
			return state;
		}
	}
};

export default persistReducer(
	{
		key: 'userGroups',
		storage: localForage,
		whitelist: localStorage.getItem('persist') ? undefined : []
	},
	userGroupsReducer
);

// Selectors

export const getUserGroupsById = ({ app: { selectedLicenseGroupId }, userGroups }: RootState) =>
	userGroups[selectedLicenseGroupId].byId;

export const getUserGroups = createSelector([getUserGroupsById], userGroupsById => Object.values(userGroupsById));

export const getUserGroupById = (userGroupId: UserGroup['id']) => (state: RootState) =>
	getUserGroupsById(state)[userGroupId];
