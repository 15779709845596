import localForage from 'localforage';
import { persistReducer } from 'redux-persist';
import { RootState } from 'app/store';
import { SiteState, SiteActionTypes } from 'app/store/types';

const initialState: SiteState = {
	region: undefined!, // HACK::assume never undefined and handle check only in <InitializeApp />
	isSupportedRegion: true
};

const siteReducer = (state = initialState, action: SiteActionTypes) => {
	switch (action.type) {
		case 'GET_REGION_SUCCESS':
			return {
				...state,
				region: action.payload.region
			};
		case 'GET_IS_SUPPORTED_REGION_SUCCESS': {
			return {
				...state,
				isSupportedRegion: action.payload.isSupportedRegion
			};
		}
		case 'PURGE_STATE':
			return initialState;
		default: {
			return state;
		}
	}
};

export default persistReducer(
	{
		key: 'site',
		storage: localForage,
		whitelist: ['region']
	},
	siteReducer
);

// Selectors
export const getRegion = ({ site }: RootState) => site.region;
export const getIsSupportedRegion = ({ site }: RootState) => site.isSupportedRegion;
export const getIsPossibleDevelopStore = (state: RootState) =>
	[
		'AR',
		'AT',
		'BA',
		'BE',
		'BG',
		'BY',
		'CH',
		'CI',
		'CY',
		'CZ',
		'DE',
		'DK',
		'EG',
		'ES',
		'FI',
		'FR',
		'GB',
		'GE',
		'GH',
		'GR',
		'HR',
		'HU',
		'IE',
		'IL',
		'IT',
		'KE',
		'LB',
		'LI',
		'LT',
		'LU',
		'MA',
		'MG',
		'MT',
		'NG',
		'NL',
		'NO',
		'PL',
		'PT',
		'RO',
		'RS',
		'RU',
		'SE',
		'SI',
		'SK',
		'TN',
		'TR',
		'UA',
		'ZA'
	].includes(getRegion(state));
