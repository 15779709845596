import ListItem from '@material-ui/core/ListItem';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import * as Actions from 'app/store/actions';
import clsx from 'clsx';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'app/modules/react-redux';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { IconButton, Tooltip } from '@material-ui/core';
import { MailOutline } from '@material-ui/icons';

import HelpIconLine from '@material-ui/icons/HelpOutline';
import InboxIcon from '@material-ui/icons/InboxOutlined';
import TrackerIcon from '@material-ui/icons/ExploreOutlined';
import { useSelector } from 'app/modules/react-redux';
import { getUserPreferences } from 'app/store/reducers';
import { UserPreferences } from 'app/store/types';
import { Link } from 'react-router-dom';
import {
	AddAppIcon,
	CreateWorkflowIcon,
	ProfileIcon,
	QuickLinksIcon,
	NewFormIcon,
	NewRoleIcon,
	AddSingleDeviceIcon,
	ImportDevicesIcon,
	RolesIconLine,
	LogIconLine,
	FormIconLine,
	UserIconLine,
	DeviceIconLine,
	ImportIcon,
	JobsIcon,
	JobQueueIcon,
	JobQueueManagementIcon
} from 'app/components/Icons';
import { getHelpUrl } from 'app/utils/helpers';

const useStyles = makeStyles(theme => ({
	item: {
		height: 40,
		borderRadius: 0,
		paddingRight: 12,
		paddingLeft: 24,
		border: '1px solid transparent', // required to prevent reflow when `active` class is added
		backgroundColor: 'transparent !important', // prevent navbar's hover effects
		// '&.active': {
		// 	border: '1px solid #7070705E',
		// 	backgroundColor: '#231E245E',
		// 	color: `${theme.palette.secondary.contrastText}!important`,
		// 	transition: 'border-radius .15s cubic-bezier(0.4,0.0,0.2,1)',
		// 	'& .nav-list-item-text-primary': {
		// 		color: '#A48ADB',
		// 		fontWeight: 700
		// 	},
		// 	'& .nav-list-item-icon': {
		// 		color: '#A48ADB'
		// 	}
		// },
		'& .nav-list-item-icon': {
			marginRight: 16,
			transform: 'scale(1.5) translateX(-1px)' // HACK-ish::required to re-center the icon dues to the 1px border
		},
		'& .nav-list-item-text': {},
		// cursor: 'pointer',
		textDecoration: 'none!important',
		'& .fill-icon': {
			display: 'none'
		},
		'&:hover .fill-icon, &.active .fill-icon': {
			display: 'inline-block'
		},
		'&:hover .line-icon, &.active .line-icon': {
			display: 'none'
		}
	}
}));

function NavVerticalQuickLinks() {
	// const userRole = useSelector(({ auth }) => auth.user.role);
	const dispatch = useDispatch();

	const theme = useTheme();
	const mdDown = useMediaQuery(theme.breakpoints.down('md'));
	const classes = useStyles();
	const { t, i18n } = useTranslation();

	const { quickLinks } = useSelector(getUserPreferences);

	const quickLinksMap: {
		[key in NonNullable<UserPreferences['quickLinks'][number]>]: {
			title: string;
			icon: React.ReactNode;
		} & (
			| { onClick: () => void; to?: never; href?: never }
			| { onClick?: never; to: string; href?: never }
			| { onClick?: never; to?: never; href: string }
		);
	} = {
		profile: {
			title: t('quick link:profile'),
			icon: <ProfileIcon />,
			to: '/admin/profile'
		},
		help: {
			title: t('quick link:help'),
			icon: <HelpIconLine />,
			href: getHelpUrl(i18n.language)
		},
		inbox: {
			title: t('quick link:inbox'),
			icon: <InboxIcon />,
			to: '/admin/home/inbox'
		},
		'job-queue': {
			title: t('quick link:job-queue'),
			icon: <JobQueueIcon />,
			to: '/admin/home/job-queue'
		},
		'job-queue-management': {
			title: t('quick link:job-queue-management'),
			icon: <JobQueueManagementIcon />,
			to: '/admin/home/job-queue/job-queue-management'
		},
		tracker: {
			title: t('quick link:tracker'),
			icon: <TrackerIcon />,
			to: '/admin/home/tracker'
		},
		jobs: {
			title: t('quick link:jobs'),
			icon: <JobsIcon />,
			to: '/admin/home/jobs'
		},
		'add-app': {
			title: t('quick link:add-app'),
			icon: <AddAppIcon />,
			to: '/admin/apps?addApp'
		},
		'create-workflow': {
			title: t('quick link:create-workflow'),
			icon: <CreateWorkflowIcon />,
			to: '/admin/workflows?createWorkflow'
		},
		'import-workflows': {
			title: t('quick link:import-workflows'),
			icon: <ImportIcon />,
			to: '/admin/workflows?importWorkflows'
		},
		devices: {
			title: t('quick link:devices'),
			icon: <DeviceIconLine />,
			to: '/admin/devices'
		},
		'import-devices': {
			title: t('quick link:import-devices'),
			icon: <ImportDevicesIcon />,
			to: '/admin/devices?addDevices'
		},
		'add-device': {
			title: t('quick link:add-device'),
			icon: <AddSingleDeviceIcon />,
			to: '/admin/devices?addDevice'
		},
		users: {
			title: t('quick link:users'),
			icon: <UserIconLine />,
			to: '/admin/users'
		},
		'invite-users': {
			title: t('quick link:invite-users'),
			icon: <MailOutline />,
			to: '/admin/users?addUsers'
		},
		forms: {
			title: t('quick link:forms'),
			icon: <FormIconLine />,
			to: '/admin/forms'
		},
		'create-form': {
			title: t('quick link:create-form'),
			icon: <NewFormIcon />,
			to: '/admin/forms?createForm'
		},
		logs: {
			title: t('quick link:logs'),
			icon: <LogIconLine />,
			to: '/admin/logs'
		},
		roles: {
			title: t('quick link:roles'),
			icon: <RolesIconLine />,
			to: '/admin/roles'
		},

		'create-role': {
			title: t('quick link:create-role'),
			icon: <NewRoleIcon />,
			to: '/admin/roles?createRole'
		}
	};

	// const hasPermission = useMemo(() => FuseUtils.hasPermission(item.auth, userRole), [item.auth, userRole]);

	// if (!hasPermission) {
	// 	return null;
	// }

	// const IconComponent = item.Icon;
	const LineIconComponent = QuickLinksIcon;
	const FillIconComponent = QuickLinksIcon;
	if (!quickLinks.length) {
		return <></>;
	}

	return (
		<ListItem
			// button
			// component={NavLinkAdapter}
			// to={item.url}
			// activeClassName="active"
			className={clsx(classes.item, 'nav-list-item mt-12 starter:text-white landing-toolbar-text-white')}
			onClick={() => mdDown && dispatch(Actions.navbarCloseMobile())}
			// exact={item.exact}
		>
			{quickLinks.length > 1 && (
				<>
					{/* <IconComponent className="nav-list-item-icon text-16 flex-shrink-0 scale-150" color="action" /> */}
					<LineIconComponent className="nav-list-item-icon text-16 flex-shrink-0 scale-150 line-icon" />
					<FillIconComponent className="nav-list-item-icon text-16 flex-shrink-0 scale-150 fill-icon" />
				</>
			)}
			<div
				className="icon-button-text-inherit-wrapper"
				style={{
					marginLeft: quickLinks.length > 1 ? undefined : -17 // align quick link with other navbar icons
				}}
			>
				{quickLinks.map(quickLink => {
					const { title, icon, onClick, to, href } = quickLinksMap[quickLink];
					if (onClick) {
						return (
							<Tooltip key={title} title={title}>
								<IconButton onClick={onClick}>{icon}</IconButton>
							</Tooltip>
						);
					}
					if (to) {
						return (
							<Tooltip key={title} title={title}>
								<IconButton component={Link} role="button" to={to}>
									{icon}
								</IconButton>
							</Tooltip>
						);
					}
					return (
						<Tooltip key={title} title={title}>
							<IconButton
								component="a"
								target="_blank"
								rel="noreferrer noopener"
								role="button"
								href={href}
							>
								{icon}
							</IconButton>
						</Tooltip>
					);
				})}
			</div>
		</ListItem>
	);
}

// const NavVerticalItem = withRouter(React.memo(NavVerticalQuickLinks));
// const NavVerticalQuickLinks = withRouter(React.memo(NavVerticalQuickLinks)) as typeof NavVerticalQuickLinks;

export default NavVerticalQuickLinks;
