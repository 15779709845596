import NavLinkAdapter from '@fuse/core/NavLinkAdapter';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import FuseNavBadge from '../FuseNavBadge';

const useStyles = makeStyles(theme => ({
	root: {
		minHeight: 48,
		'&.active': {
			backgroundColor: `${theme.palette.secondary.main} !important`,
			color: `${theme.palette.secondary.contrastText} !important`,
			pointerEvents: 'none',
			'& .nav-list-item-text-primary': {
				color: 'inherit'
			},
			'& .nav-list-item-icon': {
				color: 'inherit'
			}
		},
		'& .nav-list-item-icon': {},
		'& .nav-list-item-text': {
			padding: '0 0 0 16px'
		},
		color: theme.palette.text.primary,
		textDecoration: 'none!important'
	}
}));

function FuseNavHorizontalItem(props) {
	// const userRole = useSelector(({ auth }) => auth.user.role);

	const classes = useStyles(props);
	const { item } = props;
	const { t } = useTranslation();

	// const hasPermission = useMemo(() => FuseUtils.hasPermission(item.auth, userRole), [item.auth, userRole]);

	// if (!hasPermission) {
	// 	return null;
	// }

	const IconComponent = item.Icon;

	return (
		<ListItem
			button
			component={NavLinkAdapter}
			to={item.url}
			activeClassName="active"
			className={clsx('nav-list-item', classes.root)}
			exact={item.exact}
		>
			<IconComponent className="nav-list-item-icon text-16 flex-shrink-0 scale-150" color="action" />

			<ListItemText
				className="nav-list-item-text"
				primary={item.translate ? t(item.translate) : item.title}
				classes={{ primary: 'text-14 nav-list-item-text-primary' }}
			/>

			{item.badge && <FuseNavBadge className="ml-8" badge={item.badge} />}
		</ListItem>
	);
}

FuseNavHorizontalItem.propTypes = {
	item: PropTypes.shape({
		id: PropTypes.string.isRequired,
		title: PropTypes.string,
		icon: PropTypes.string,
		url: PropTypes.string
	})
};

const NavHorizontalItem = withRouter(React.memo(FuseNavHorizontalItem));

export default NavHorizontalItem;
