
export function hideMessage() {
	return {
		type: 'HIDE_MESSAGE'
	};
}

export function showMessage(options) {
	return {
		type: 'SHOW_MESSAGE',
		options
	};
}
