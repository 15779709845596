import FuseSplashScreen from '@fuse/core/FuseSplashScreen';
import FuseLoading from '@fuse/core/FuseLoading';
import React from 'react';

/**
 * React Suspense defaults
 * For to Avoid Repetition
 */

type Props = {
	fullscreen?: boolean;
	loadingProps?: React.ComponentProps<typeof FuseLoading>;
	children: React.ReactNode;
};
function FuseSuspense(props: Props) {
	return (
		<React.Suspense fallback={props.fullscreen ? <FuseSplashScreen /> : <FuseLoading {...props.loadingProps} />}>
			{props.children}
		</React.Suspense>
	);
}

export default FuseSuspense;
