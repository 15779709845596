import localForage from 'localforage';
import { persistReducer } from 'redux-persist';
import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'app/store';
import { FormGroup, FormGroupsState, FormGroupsActionTypes } from 'app/store/types';

const initialState: FormGroupsState = {};

const formGroupsReducer = (state = initialState, action: FormGroupsActionTypes) => {
	switch (action.type) {
		case 'GET_LICENSE_GROUP_DATA_SUCCESS': {
			const {
				licenseGroupId,
				data: { formGroups }
			} = action.payload;

			let updatedGroups;
			if (formGroups) {
				// maintain order since order is not returned in that api call
				const groups = { ...state[licenseGroupId]?.byId };

				const existingGroups = Object.keys(formGroups)
					.filter(id => groups[id] && typeof groups[id]?.groupOrder === 'number')
					.reduce((result: Record<string, FormGroup>, id) => {
						const existingGroup = groups[id];
						result[id] = {
							...formGroups[id],
							groupOrder: existingGroup.groupOrder
						};
						return result;
					}, {});

				const existingGroupOrders = Object.values(existingGroups)
					.filter((group: FormGroup) => typeof group.groupOrder === 'number')
					.map((group: FormGroup) => group.groupOrder as number);
				const maxGroupOrder = existingGroupOrders.length > 0 ? Math.max(...existingGroupOrders) : -1;

				// Assign groupOrder to the rest of the groups
				const newGroups = Object.keys(formGroups)
					.filter(id => !groups[id] || typeof groups[id]?.groupOrder !== 'number')
					.reduce((result: Record<string, FormGroup>, id, index) => {
						result[id] = {
							...formGroups[id],
							groupOrder: maxGroupOrder + 1 + index
						};
						return result;
					}, {});

				updatedGroups = {
					...existingGroups,
					...newGroups
				};
			}
			return {
				...state,
				[licenseGroupId]: {
					...state[licenseGroupId],
					...(updatedGroups && { byId: updatedGroups })
				}
			};
		}
		case 'GET_FORM_GROUP_ORDER_SUCCESS': {
			const { order, licenseGroupId } = action.payload;
			const groups = { ...state[licenseGroupId].byId };
			// order list may not contain all groups
			const existingGroupIds = order.filter((groupId: string) => groups[groupId]);
			const updatedGroups: Record<string, FormGroup> = {};

			existingGroupIds.forEach((groupId: string, idx: number) => {
				const group = { ...groups[groupId] };
				group.groupOrder = idx + 1;
				updatedGroups[groupId] = group;
			});

			// assign other group order for non existing groups in order array
			const nonOrderedGroups = Object.keys(groups).filter(groupId => !existingGroupIds.includes(groupId));

			nonOrderedGroups.forEach((groupId, idx) => {
				const group = { ...groups[groupId] };
				updatedGroups[groupId] = group;
			});

			return {
				...state,
				[licenseGroupId]: {
					byId: updatedGroups
				}
			};
		}
		case 'LOGGED_OUT_USER':
		case 'PURGE_STATE':
			return initialState;
		default: {
			return state;
		}
	}
};

export default persistReducer(
	{
		key: 'formGroups',
		storage: localForage,
		whitelist: localStorage.getItem('persist') ? undefined : []
	},
	formGroupsReducer
);

// Selectors
export const getFormGroupsById = ({ app: { selectedLicenseGroupId }, formGroups }: RootState) =>
	formGroups[selectedLicenseGroupId].byId;

export const getFormGroups = createSelector([getFormGroupsById], formGroupsById => Object.values(formGroupsById));

export const getFormGroupById = (formGroupId: FormGroup['id']) => (state: RootState) =>
	getFormGroupsById(state)[formGroupId];
