import { combineReducers } from '@reduxjs/toolkit';
import dialog from './dialog.reducer';
import message from './message.reducer';
import navbar from './navbar.reducer';
import navigation from './navigation.reducer';
import routes from './routes.reducer';
import settings from './settings.reducer';
import quickPanel from './quickPanel.reducer';

const fuseReducers = combineReducers({
	navigation,
	settings,
	quickPanel,
	navbar,
	message,
	dialog,
	routes
});

export default fuseReducers;
