import Cookies from 'cookies-js';
// @ts-ignore: missing type declaration file
import { CookieStorage } from 'redux-persist-cookie-storage';
import { persistReducer, createTransform } from 'redux-persist';
import { createSelector } from '@reduxjs/toolkit';
import _ from '@lodash';
import { RootState } from 'app/store';
import { LicenseGroupsState, LicenseGroupsActionTypes, PublicId } from 'app/store/types';
import { getRootDomain } from 'app/utils/helpers';

const initialState: LicenseGroupsState = {
	adminedById: undefined!, // HACK::assume never undefined and handle check only in <LicenseGroupWrapper /> and <ToolbarLayoutAdmin />
	managedById: undefined!, // HACK::assume never undefined and handle check elsewhere
	paragonData: undefined!,
	managedGroups: undefined!,
	loadingGroups: true,
	tenantGroupDetailsById: undefined!
};

const licenseGroupsReducer = (state = initialState, action: LicenseGroupsActionTypes) => {
	switch (action.type) {
		case 'GET_ADMINED_LICENSE_GROUPS_SUCCESS': {
			return {
				...state,
				adminedById: action.payload.data
			};
		}
		case 'GET_MANAGED_LICENSE_GROUPS_SUCCESS': {
			return {
				...state,
				managedById: action.payload.data
			};
		}
		case 'GET_TENANT_MANAGER_GROUPS_SUCCESS': {
			return {
				...state,
				managedGroups: action.payload.data
			};
		}

		case 'GET_TENANT_BY_ID_SUCCESS': {
			const { adminInfo, id } = action.payload;
			// console.log('action.payload', action.payload);

			return {
				...state,
				managedById: {
					...state.managedById,
					[id]: {
						...state.managedById?.[id],
						contactInfo: {
							...adminInfo
						}
					}
				}
			};
		}

		case 'GET_TENANT_GROUP_BY_ID_SUCCESS': {
			const { id, data: tenantGroup } = action.payload;
			return {
				...state,
				managedGroups: {
					...state.managedGroups,
					[id]: {
						...tenantGroup
					}
				},
				loadingGroups: false
			};
		}

		case 'GET_GROUP_MANAGER_INFO_BY_ID_SUCCESS': {
			const { groupId, data: emailInfo } = action.payload;
			return {
				...state,
				managedGroups: {
					...state.managedGroups,
					[groupId]: {
						...state.managedGroups[groupId],
						managerEmails: emailInfo
					}
				},
				loadingGroups: false
			};
		}

		case 'SET_LOADING_MANAGER_GROUPS': {
			const { loading } = action.payload;
			return {
				...state,
				loadingGroups: loading
			};
		}

		case 'GET_SELECTED_TENANT_GROUP_INFO_SUCCESS': {
			const { tenantId, data: details } = action.payload;
			return {
				...state,
				tenantGroupDetailsById: {
					...state.tenantGroupDetailsById,
					[tenantId]: {
						groupId: details.groupId,
						groupName: details.groupName,
						managers: details.managers,
						parentId: details.parentId,
						protected: details?.protected
					}
				}
			};
		}

		case 'FORGET_ADMINED_LICENSE_GROUP_SUCCESS': {
			const { licenseGroupId } = action.payload;
			return {
				...state,
				adminedById: _.omit(state.adminedById, [licenseGroupId])
			};
		}
		case 'GET_LICENSE_GROUP_DATA_SUCCESS': {
			const {
				licenseGroupId,
				data: { details, entitlements }
			} = action.payload;

			return {
				...state,
				adminedById: {
					...state.adminedById,
					[licenseGroupId]: {
						// DEV NOTE::this will adding this license group to the `adminedById` obejct - meaning checking if said object is `undefined` _won't_ be synonymous with "data loaded" - may need to come back to this
						...state.adminedById?.[licenseGroupId],
						...(details && details),
						...(entitlements && { permissions: entitlements.features }),
						// HACK-ish::add prop to say additional data has loaded
						...(details && { dataLoaded: true })
					}
				}
			};
		}
		case 'GET_PARAGON_DATA_SUCCESS': {
			const paragonData = action.payload;

			return {
				...state,
				paragonData
			};
		}
		case 'LOGGED_OUT_USER': {
			return {
				...initialState,
				adminedById: state.adminedById
			};
		}
		case 'PURGE_STATE':
			return initialState;
		default: {
			return state;
		}
	}
};

const cookieStorageOptions = {
	expiration: {
		default: 2147483647
	},
	setCookieOptions: {
		// DEV NOTE::cookies will not work for localhost (_could_ probably set up a HOST domain for this to work locally?)
		domain: process.env.NODE_ENV === 'development' ? undefined : getRootDomain()
	}
};

export default persistReducer(
	{
		key: 'tenants', // DEV NOTE::do not rename this key! (This needs to persist on the browser; because I suspect a rename of "license group", I'm using the future name here)
		storage: new CookieStorage(Cookies, cookieStorageOptions),
		whitelist: localStorage.getItem('persist') ? ['adminedById'] : [],
		transforms: [
			// don't persist `dataLoaded` in licenseGroup data
			createTransform(
				inboundState => {
					return _.mapValues(inboundState, licenseGroupData => ({
						...licenseGroupData,
						dataLoaded: undefined
					}));
				},
				undefined,
				{ whitelist: localStorage.getItem('persist') ? ['adminedById'] : [] }
			)
		]
	},
	licenseGroupsReducer
);

// Selectors
export const getAdminedLicenseGroupsById = ({ licenseGroups }: RootState) => licenseGroups.adminedById;
export const getManagedLicenseGroupsById = ({ licenseGroups }: RootState) => licenseGroups.managedById;
export const getTenantManagerGroupsById = ({ licenseGroups }: RootState) => licenseGroups.managedGroups;

export const getAdminedLicenseGroups = createSelector([getAdminedLicenseGroupsById], adminedLicenseGroupsById =>
	adminedLicenseGroupsById ? Object.values(adminedLicenseGroupsById) : undefined!
);
export const getManagedLicenseGroups = createSelector([getManagedLicenseGroupsById], managedLicenseGroupsById =>
	managedLicenseGroupsById ? Object.values(managedLicenseGroupsById) : undefined!
);
export const getHasManagerPage = createSelector(
	[getManagedLicenseGroupsById, getTenantManagerGroupsById],
	(managedLicenseGroupsById, managerGroupsById) =>
		Object.values(managedLicenseGroupsById ?? {}).length > 0 || Object.values(managerGroupsById ?? {}).length > 0
);

export const getLoadingTenantGroups = ({ licenseGroups }: RootState) => licenseGroups.loadingGroups;

export const getSelectedLicenseGroupData = ({ app: { selectedLicenseGroupId }, licenseGroups }: RootState) =>
	licenseGroups.adminedById && selectedLicenseGroupId
		? licenseGroups.adminedById[selectedLicenseGroupId]
		: undefined!;

export const getSelectedLicenseGroupDataReloaded = createSelector(
	[getSelectedLicenseGroupData],
	selectedLicenseGroupData => selectedLicenseGroupData?.dataLoaded ?? false
);

export const getSelectedLicenseGroupManagerDetails = ({ app: { selectedLicenseGroupId }, licenseGroups }: RootState) =>
	licenseGroups.tenantGroupDetailsById && selectedLicenseGroupId
		? licenseGroups.tenantGroupDetailsById[selectedLicenseGroupId]
		: undefined!;

export const getIsSelectedLicenseGroupProductionSubscriptionLicense = ({
	app: { selectedLicenseGroupId },
	licenseGroups
}: RootState) =>
	selectedLicenseGroupId &&
	licenseGroups.adminedById[selectedLicenseGroupId].orderType === 'PRODUCTION' &&
	![
		PublicId.StarterYearlyTerm,
		PublicId.BusinessYearlyTerm,
		PublicId.EnterpriseYearlyTerm,
		PublicId.FreeTrial,
		PublicId.NFR
	].includes(licenseGroups.adminedById[selectedLicenseGroupId].catalogPublicId);
export const getSelectedLicenseGroupTimezone = ({ app: { selectedLicenseGroupId }, licenseGroups }: RootState) => {
	return (
		licenseGroups.adminedById[selectedLicenseGroupId ?? '']?.timezone ??
		Intl.DateTimeFormat().resolvedOptions().timeZone
	);
};
export const getTimezoneOffsetInMinutes = ({ app: { selectedLicenseGroupId }, licenseGroups }: RootState) => {
	const timezoneOffset = licenseGroups.adminedById?.[selectedLicenseGroupId ?? '']?.timezoneOffset;

	if (typeof timezoneOffset === 'string') {
		const parsedOffset = parseFloat(timezoneOffset);
		return Number.isNaN(parsedOffset) ? 0 : parsedOffset;
	}

	return typeof timezoneOffset === 'number' && !Number.isNaN(timezoneOffset)
		? timezoneOffset
		: -new Date().getTimezoneOffset();
};
export const getLocalTimezoneDifferenceInMinutes = createSelector(
	[getTimezoneOffsetInMinutes],
	timezoneOffsetInMinutes => timezoneOffsetInMinutes - -new Date().getTimezoneOffset()
);
export const getParagonData = ({ licenseGroups }: RootState) => licenseGroups.paragonData;
// export const getFeaturePermissionCheck = (_state: RootState) => {
// 	// HACK::all plans her full permissions in Stratus
// 	return (_permission: string) => !!localStorage.getItem(_permission);

// 	// const licenseGroupData = getSelectedLicenseGroupData(state) as
// 	// 	| ReturnType<typeof getSelectedLicenseGroupData>
// 	// 	| undefined; // HACK::use actual type here - assume never undefined within the wrapped app

// 	// if (!licenseGroupData || !licenseGroupData.dataLoaded) {
// 	// 	return () => false;
// 	// }

// 	// const starterPlanPermissions: string[] = [];
// 	// const businessPlanPermissions: string[] = [
// 	// 	...starterPlanPermissions,
// 	// 	'frequency_settings',
// 	// 	'advanced_device_management',
// 	// 	'password_management', // also covers "password monitoring" and "password export tool" ATM
// 	// 	'password_remediation',
// 	// 	'multi_admin_support' // also covers "role-based permissions",
// 	// ];
// 	// const enterprisePlanPermissions: string[] = [...businessPlanPermissions, 'policy_remediation'];

// 	// const planPermissions = {
// 	// 	[PublicId.StarterMonthly]: starterPlanPermissions,
// 	// 	[PublicId.StarterYearly]: starterPlanPermissions,
// 	// 	[PublicId.StarterYearlyTerm]: starterPlanPermissions,
// 	// 	[PublicId.BusinessMonthly]: businessPlanPermissions,
// 	// 	[PublicId.BusinessYearly]: businessPlanPermissions,
// 	// 	[PublicId.BusinessYearlyTerm]: businessPlanPermissions,
// 	// 	[PublicId.EnterpriseMonthly]: enterprisePlanPermissions,
// 	// 	[PublicId.EnterpriseYearly]: enterprisePlanPermissions,
// 	// 	[PublicId.EnterpriseYearlyTerm]: enterprisePlanPermissions
// 	// };

// 	// const selectedLicenseGroupPlan = licenseGroupData.catalogPublicId;
// 	// const selectedLicenseGroupPlanPermissions = planPermissions[selectedLicenseGroupPlan];

// 	// return (permission: string) => selectedLicenseGroupPlanPermissions.includes(permission);
// };

// // export const getProfilePermissionCheck = (state: RootState) => {
// // 	const licenseGroupData = getSelectedLicenseGroupData(state) as
// // 		| ReturnType<typeof getSelectedLicenseGroupData>
// // 		| undefined; // HACK::use actual type here - assume never undefined within the wrapped app

// // 	if (!licenseGroupData || !licenseGroupData.dataLoaded) {
// // 		return () => false;
// // 	}

// // 	const users = getUsers(state);
// // 	const profile = getProfile(state);

// // 	const profilePermissionsForSelectedLicenseGroup = users.find(user => user.id === profile.id)?.permissions ?? {};

// // 	return (profilePermission: string) => {
// // 		// HACK::TEMP::only a `tenant-admin` permission exists in stratus ATM
// // 		if (profilePermission !== 'tenant-admin') {
// // 			return true;
// // 		}

// // 		// TODO::how should this work in Stratus?
// // 		// disable all permissions if license expired (besides `license_management`)
// // 		// if (
// // 		// 	licenseGroupData.expirationDate &&
// // 		// 	Date.now() > licenseGroupData.expirationDate &&
// // 		// 	profilePermission !== 'license_management'
// // 		// ) {
// // 		// 	return false;
// // 		// }

// // 		return !!profilePermissionsForSelectedLicenseGroup[profilePermission];
// // 	};
// // };
