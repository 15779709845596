import _ from '@lodash';
import IconButton from '@material-ui/core/IconButton';
import * as Actions from 'app/store/actions';
import React from 'react';
import { useDispatch, useSelector } from 'app/modules/react-redux';
import { PinIcon } from 'app/components/Icons';
import Tooltip from '@material-ui/core/Tooltip';
import { useTranslation } from 'react-i18next';

function NavbarFoldedToggleButton(props) {
	const dispatch = useDispatch();
	const settings = useSelector(({ fuse }) => fuse.settings.current);
	const { t } = useTranslation();

	return (
		<Tooltip title={settings.layout.config.navbar.folded ? t('nav:pin') : t('nav:unpin')}>
			<IconButton
				className={props.className}
				onClick={() => {
					dispatch(
						Actions.setDefaultSettings(
							_.set({}, 'layout.config.navbar.folded', !settings.layout.config.navbar.folded),
							true
						)
					);
				}}
				color="inherit"
				style={{
					background: '#231231',
					height: 28,
					width: 22,
					position: 'absolute',
					right: 0,
					top: 5,
					borderRadius: '3px 0 0 3px',
					visibility: settings.layout.config.navbar.folded ? 'hidden' : 'visible'
				}}
			>
				{/* {props.children} */}
				<PinIcon
					htmlColor={settings.layout.config.navbar.folded ? 'currentColor' : 'white'}
					style={{
						transform: settings.layout.config.navbar.folded ? 'rotate(45deg)' : 'none',
						// fill: settings.layout.config.navbar.folded ? 'transparent' : 'white',
						padding: 2
					}}
				/>
			</IconButton>
		</Tooltip>
	);
}

export default NavbarFoldedToggleButton;
