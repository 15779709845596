import React from 'react';
// @ts-ignore
import { VelocityComponent } from 'velocity-react';
import 'velocity-animate/velocity.ui';

type Props = {
	animation?: string;
	runOnMount?: true;
	targetQuerySelector?: any;
	interruptBehavior?: string;
	visibility?: string;
	duration?: number;
	delay?: number;
	easing?: [number, number, number, number];
	display?: string | null;
	setRef?: any;
	className?: string;
	// children: React.ReactNode;
	children: NonNullable<any>;
};

const defaultProps = {
	animation: 'transition.fadeIn',
	runOnMount: true,
	targetQuerySelector: null,
	interruptBehavior: 'stop',
	visibility: 'visible',
	duration: 300,
	delay: 50,
	easing: [0.4, 0.0, 0.2, 1],
	display: null,
	setRef: undefined
} as const;

const FuseAnimate = React.forwardRef((props: Props, ref) => {
	const propsWithDefaults = {
		...defaultProps,
		...props
	};

	const children = React.cloneElement(propsWithDefaults.children, {
		style: {
			...propsWithDefaults.children.style,
			visibility: 'hidden'
		}
	} as any);
	return (
		<VelocityComponent ref={ref} {...propsWithDefaults}>
			{children}
		</VelocityComponent>
	);
});

export default React.memo(FuseAnimate);
