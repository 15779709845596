import Icon from '@material-ui/core/Icon';
import IconButton from '@material-ui/core/IconButton';
import * as Actions from 'app/store/actions';
import React from 'react';
import { useDispatch } from 'app/modules/react-redux';

function NavbarMobileToggleButton({ className = '', children = <Icon>menu</Icon> }) {
	const dispatch = useDispatch();

	return (
		<IconButton
			className={`${className} starter:text-white landing-toolbar-text-white`}
			onClick={_ev => dispatch(Actions.navbarToggleMobile())}
			// disableRipple
			style={{ height: 32, width: 32, margin: 4 }}
		>
			{children}
		</IconButton>
	);
}

export default NavbarMobileToggleButton;
