import localForage from 'localforage';
import { persistReducer } from 'redux-persist';
import { RootState } from 'app/store';
import { MarketState, MarketActionTypes } from 'app/store/types';

const initialState: MarketState = {
	currency: undefined!, // HACK::assume never undefined and handle check only in <InitializeApp />
	catalog: undefined! // HACK::assume never undefined and handle check only in <InitializeApp />
};

const marketReducer = (state = initialState, action: MarketActionTypes) => {
	switch (action.type) {
		case 'GET_CATALOG_SUCCESS': {
			const { currency, catalog } = action.payload;
			return {
				...state,
				currency,
				// CHANGEME::TEMP::remove this mock license!
				catalog: {
					...catalog,
					'5837D291-31A7-47C2-B9B6-6712468C8B73': {
						publicId: '5837D291-31A7-47C2-B9B6-6712468C8B73' as any,
						name: 'Dispatcher Stratus Test Catalog Item',
						productionDuration: 1,
						showPrice: true,
						defaultPrice: 2.5,
						priceRanges: [
							{ sapId: 'FAKESGAAA11', amount: 2.5, min: 1 },
							{ sapId: 'FAKESGAAA12', amount: 1.5, min: 11 },
							{ sapId: 'FAKESGAAA13', amount: 1.3, min: 50 },
							{ sapId: 'FAKESGAAA14', amount: 1.1, min: 250 },
							{ sapId: 'FAKESGAAA15', amount: 0.8, min: 1000 }
						],
						billing: {
							name: 'One Time Fee',
							uid: 'bonce' as const,
							duration: 0,
							months: false,
							gracePeriod: 0
						},
						counter: {
							min: 1,
							max: 10000,
							step: 1,
							lock: false
						}
					}
				}
			};
		}
		case 'PURGE_STATE':
			return initialState;
		default: {
			return state;
		}
	}
};

export default persistReducer(
	{
		key: 'market',
		storage: localForage,
		blacklist: []
	},
	marketReducer
);

// Selectors
export const getCurrency = ({ market }: RootState) => market.currency;
export const getCatalog = ({ market }: RootState) => market.catalog;
