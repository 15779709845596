import { ThemeProvider } from '@material-ui/core/styles';
import React, { useEffect, useLayoutEffect } from 'react';
import { useSelector } from 'app/modules/react-redux';
import { getUserPreferences } from 'app/store/reducers';

const useEnhancedEffect = typeof window === 'undefined' ? useEffect : useLayoutEffect;

type Props = {
	children: React.ReactNode;
};

const FuseTheme = ({ children }: Props) => {
	const userPreferences = useSelector(getUserPreferences) as ReturnType<typeof getUserPreferences> | undefined; // HACK::use actual type here - assume never undefined within the wrapped app

	const mainTheme = useSelector(({ fuse }) => fuse.settings.mainTheme);
	const mainThemeDark = useSelector(({ fuse }) => fuse.settings.mainThemeDark);
	const direction = useSelector(({ fuse }) => fuse.settings.defaults.direction);

	useEnhancedEffect(() => {
		document.body.dir = direction;
	}, [direction]);

	// console.warn('FuseTheme:: rendered',mainTheme);
	return (
		<ThemeProvider theme={userPreferences?.appearance === 'dark' ? mainThemeDark : mainTheme}>
			{children}
		</ThemeProvider>
	);
};

export default React.memo(FuseTheme);
